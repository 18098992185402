<template>
  <div class="page">
    <div class="container">
      <div class="header">
        {{ configDetail.title }}
      </div>
      <div class="technologyBody">
        <img src="@/static/images/gameTrading.jpg" alt="" class="iconImg" />
        <div class="text">{{ configDetail.iconTitle }}</div>
      </div>
      <div class="technologyFooter">
        <div class="describe">
          {{ configDetail.describe }}
        </div>
        <div class="mail">联系邮箱：{{ configDetail.mail }}</div>
        <div class="phone">联系电话：{{ configDetail.phone }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameTradingTechnology",
  data() {
    return {
      configDetail: {
        title: "技术支持",
        iconTitle: "游戏交易",
        describe:
          "游戏交易是一款实用的商品价值估算工具，能准确估算您的商品价格以及相应的定位!",
        mail: "1qwrhl@sina.com",
        phone: "+86-15223001356",
      },
    };
  },
};
</script>

<style lang="less" scoped>
.page {
  overflow: hidden;
  .container {
    width: 320px;
    margin: 0 auto;
    .header {
      font-size: 24px;
      padding: 50px 0px 25px 0px;
      font-weight: 600;
    }
    .technologyBody {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      margin-top: 80px;
      .iconImg {
        width: 80px;
        height: 80px;
      }
      .text {
        font-size: 14px;
        color: #aaaaaa;
        text-align: center;
        margin-top: 15px;
      }
    }
    .technologyFooter {
      margin-top: 50px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 20px;
      .mail {
        margin-top: 10px;
      }
      .phone {
        margin-top: 10px;
      }
    }
  }
}
</style>
